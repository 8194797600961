<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="true" />
    <PageHeader :title="title" />
    <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
      <v-container class="pt-0">
        <v-row justify="end">
          <v-col cols="1" align-self="center" align="center" class="pr-0 pl-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="!showFilters">
          <v-col cols="12" align="left">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <v-row>
              <v-col cols="12" sm="4" md="4" class="pb-0">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaDesdeSelected"
                      label="Fecha estado desde"
                      :append-icon="calendarIcon"
                      v-bind="attrs"
                      outlined
                      dense
                      hint="Formato DD/MM/AAAA"
                      @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                      v-on="on"
                      :rules="[
                        (!!fechaDesdeSelected &&
                          !!fechaHastaSelected &&
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected))) ||
                          'Formato incorrecto'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaDesde"
                    no-title
                    @change="fechaDesdeSelected = formatDate(fechaDesde)"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Fecha hasta -->
              <v-col cols="12" sm="4" md="4" class="pb-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaHastaSelected"
                      label="Fecha estado hasta"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="[
                        (!!fechaDesdeSelected &&
                          !!fechaHastaSelected &&
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected))) ||
                          'Formato incorrecto'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaHasta"
                    no-title
                    @change="fechaHastaSelected = formatDate(fechaHasta)"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="pb-0">
                <v-select
                  ref="estados-acta"
                  v-model="estadosSelected"
                  :items="estadosActa"
                  label="Estados actas"
                  item-text="value"
                  item-value="id"
                  multiple
                  outlined
                  dense
                  small-chips
                  chip
                  clearable
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ estadosSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <!-- Filtros proceso -->

            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                :disabled="!isFormValid"
                elevation="2"
                small
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        v-model="actasSelected"
        id="actasTable"
        :headers="actasHeader"
        :header-props="{ sortIcon: null }"
        class="elevation-1"
        :items="actasFiscalizacion"
        :loading="isLoading"
        :search="search"
        :single-select="singleSelect"
        show-select
        item-key="actasFiscalizacionId"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :items-per-page="itemPerPage"
      >
        <template v-slot:[`item.importe`]="{ item }">
          <span>{{ addDecimals(item.importe) }}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                  :disabled="isLoading"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-tooltip left v-if="item.estado === 'Borrador'"> -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="canEdit"
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="editActa(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar acta</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="canDelete"
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openDeleteActa(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar acta</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-row class="pr-4 pl-4 pt-4" justify="end">
      <v-btn
        color="primary"
        @click="confirmGenerate()"
        :disabled="actasSelected.length == 0"
      >
        Generar archivo
      </v-btn>
    </v-row>
    <div v-if="modalConfirmFile">
      <DatosArchivoAfip
        :modalOpen="modalConfirmFile"
        v-on:update:modalConfirmFile="modalConfirmFile = $event"
        :actas="actasSelected"
      />
    </div>
    <div v-if="validateQuantityPages">
      <v-dialog
        v-model="validateQuantityPages"
        :max-width="'60%'"
        @keydown.esc="validateQuantityPages = false"
      >
        <v-card>
          <v-card-title class="pb-8">
            <span class="headline">
              Hay actas sin seleccionar. ¿Desea continuar?
            </span>
          </v-card-title>
          <v-card-actions class="pt-1">
            <v-spacer></v-spacer>
            <v-btn outlined @click="validateQuantityPages = false">
              Cancelar
            </v-btn>
            <v-btn
              @click="modalConfirmFile = true"
              form="crud-form"
              color="primary"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <DeleteDialog
      :textDelete="textDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteActaConfirm()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import axios from "@/axios/axios-api";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import FiltersSelected from "@/components/shared/FiltersSelected";
import GoBackBtn from "@/components/shared/GoBackBtn";
import helper from "../../../../utils/helpers";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import DatosArchivoAfip from "@/components/modules/cuotas/aportes/DatosArchivoAfip.vue";

export default {
  name: "ProcesoInformeAFIP",
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    GoBackBtn,
    DatosArchivoAfip
  },
  data: vm => ({
    title: enums.titles.GENERACION_ARCHIVO_AFIP,
    searchIcon: enums.icons.SEARCH,
    rules: rules,
    textDelete: null,
    isLoading: false,
    modalConfirmFile: false,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    openDeleteDialog: false,
    validateQuantityPages: false,
    editedIndex: -1,
    showFilters: true,
    isFormValid: false,
    actasFiscalizacion: [],
    actasSelected: [],
    singleSelect: false,
    routeToGo: "GestionProcesoInformeAfip",
    periodo: null,
    search: "",
    actaToDelete: {},
    sortByProceso: "actasFiscalizacionId",
    sortDesc: true,
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    canEdit: false,
    canDelete: false,
    menu1: false,
    menu2: false,
    filtersApplied: [],
    estadosActa: [],
    estadosSelected: [],
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    actasHeader: [
      {
        text: "Acta n°",
        align: "start",
        value: "actasFiscalizacionId",
        width: "75px"
      },
      { text: "Empresa", value: "empresa", width: "200px" },
      { text: "Fecha", value: "fecha" },
      { text: "Estado", value: "estado" },
      { text: "Entidad facturante", value: "entidadFacturante" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ]
  }),
  computed: {
    ...mapGetters({}),
    sortBy: {
      get() {
        return this.sortByProceso;
      },
      set(newVal) {
        return newVal;
      }
    }
  },
  created() {
    this.getEstadosActa();
  },

  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.$bus.$on("closeModalDatos", () => {
      this.modalConfirmFile = false;
    });

    this.$bus.$on("confirmFileAfip", data => {
      this.generarArchivo(data);
    });
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchActasFiscalizacionAfip: "aportes/fetchActasFiscalizacionAfip",
      deleteActaById: "aportes/deleteActaById",
      fetchEstadosActa: "configuracion/fetchEstadosActa",
      generarArchivoInformeAfip: "aportes/generarArchivoInformeAfip",
      getEmpresaByActaId: "aportes/getEmpresaByActaId"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_ACTA_FISCALIZACION:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ACTA_FISCALIZACION:
            this.canEdit = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      if (this.estadosSelected.length > 0) {
        this.isLoading = true;
        this.actasSelected = [];
        this.filtersApplied = this.formatFilters(this.$refs["filters-form"]);
        this.customizeFiltersApplied();
        const params = {
          fechaDesde: `${this.parseDate(this.fechaDesdeSelected)} 00:00:00`,
          fechaHasta: `${this.parseDate(this.fechaHastaSelected)} 23:59:00`,
          estadosActa:
            this.estadosSelected.length == 0 ? null : this.estadosSelected
        };

        try {
          const response = await this.fetchActasFiscalizacionAfip({ params });
          this.actasFiscalizacion = response;
          this.isLoading = false;
        } catch {
          this.isLoading = false;
        }
      } else {
        this.setAlert({
          type: "info",
          message: "Seleccione al menos un estado de acta."
        });
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied.push(
        {
          key: "fechaDesde",
          label: "Fecha estado desde",
          model: this.fechaDesdeSelected
        },
        {
          key: "fechaHasta",
          label: "Fecha estado hasta",
          model: this.fechaHastaSelected
        }
      );

      if (this.periodo) {
        this.filtersApplied.push({
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
    },
    cleanFiltersSelected() {
      this.filtersApplied = [];
    },
    editActa(item) {
      // solo si esta en estado borrador
      this.$router.push({
        name: "EditActa",
        params: { id: item.actasFiscalizacionId, parent: "ProcesoInformeAFIP" }
      });
    },
    openDeleteActa(item) {
      this.actaToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteActaConfirm() {
      var item = this.actaToDelete;
      this.editedIndex = this.actasFiscalizacion.indexOf(item);
      this.actasFiscalizacion.splice(this.editedIndex, 1);
      this.deleteActa(item);
      this.actaToDelete = {};
      this.textDelete = null;
    },

    async deleteActa(item) {
      var actaId = item.actasFiscalizacionId;
      const res = await this.deleteActaById({ actaId });

      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    resetForm() {
      this.filtersApplied = [];
      this.$refs["periodo"].reset();
    },
    formatFilters(ref) {
      if (typeof ref === "undefined") return;
      return ref.$children
        .map(f => {
          if (f.value)
            return { key: f.id, label: f.label, model: f.selectedItems };
          return null;
        })
        .filter(f => f);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },

    async getEstadosActa() {
      const estadosActa = await this.fetchEstadosActa();
      this.estadosActa = estadosActa;
    },

    async generarArchivo(data) {
      this.validateQuantityPages = false;
      let actasToSend = [];
      this.actasSelected.forEach(acta => {
        actasToSend.push({ 
          actasFiscalizacionId: acta.actasFiscalizacionId,
          empresa: acta.empresa,
          entidadFacturante: acta.entidadFacturante,
          estado: acta.estado,
          estadoId: acta.estadoId,
          estadosIds: acta.estadosIds,
          fecha: this.parseDateToIso(acta.fecha),
          importe: acta.importe
        });
      });
      const params = {
        periodoArchivo: data.periodo,
        secuenciaArchivo: data.secuencia,
        codigoINOS: data.codigo,
        actasFiscalizacionSeleccionadas: actasToSend
      };
      try {
        var cuit = "";
        for (
          var i = 0;
          i < params.actasFiscalizacionSeleccionadas.length;
          i++
        ) {
          var acta = params.actasFiscalizacionSeleccionadas[i];
          var empresa = await this.getEmpresaByActaId(
            acta.actasFiscalizacionId
          );
          cuit = empresa.entidadFiscalizadoraCuit;

          if (cuit != "" && cuit != null) {
            i = params.actasFiscalizacionSeleccionadas.length;
          }
        }

        if (params.actasFiscalizacionSeleccionadas.length > 0) {
          await this.generarArchivoInformeAfip({ params }).then(response => {
            var name =
              "F8134." +
              cuit + //Cuit de cada empresa?
              //params.CuitArchivo'' +
              "." +
              params.periodoArchivo +
              "." +
              params.secuenciaArchivo;
            if (response.status === 200 && (cuit == "" || cuit == null)) {
              this.setAlert({
                type: "info",
                message:
                  "Archivo creado con éxito pero sin CUIT informante válido."
              });
            } else if (response.status === 200) {
              this.setAlert({
                type: "success",
                message: "Archivo creado con éxito"
              });
            }
            helper.downloadFile(response.data, name, "txt");
            this.modalConfirmFile = false;
            this.actasSelected = [];
            this.$router.push({ name: "GestionProcesoInformeAfip" });
          });
        }
      } catch {}
    },

    getFormatParameter(list) {
      var result = "";
      var inicio = true;
      list.forEach(element => {
        inicio == true ? (result = result) : (result += ",");
        result += element.actasFiscalizacionId;
        inicio = false;
      });
      return result;
    },

    confirmGenerate() {
      this.modalConfirmFile = true;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer"
      })
        .then(response => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("Ocurrió un error al descargar el archivo"));
    },
    validatePages() {
      if (
        this.actasSelected.length != this.actasFiscalizacion.length &&
        this.itemPerPage < this.actasFiscalizacion.length
      ) {
        this.validateQuantityPages = true;
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
